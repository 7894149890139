<template>
  <section class="register">
    <div class="register__header">
      <h1 class="header-title">Регистрация</h1>
      <form
        class="addForm"
        @submit.prevent="sendDataRegister"
        method="POST"
        action="/register"
      >
        <label for="user_email">Ваш email</label>
        <input
          type="email"
          id="user_email"
          name="user_email"
          v-model="userEmail"
        />
        <label for="user_pass">Придумайте пароль</label>
        <input
          type="password"
          id="user_pass"
          name="user_pass"
          v-model="userPassword"
        />
        <label for="user_pass_confirm">Повторите придуманный пароль</label>
        <input
          type="password"
          id="user_pass_confirm"
          name="user_pass_confirm"
          v-model="userConfirmPass"
        />
        <button type="submit" class="radio-block__button">
          Зарегистрироваться
        </button>
      </form>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      userEmail: "",
      userPassword: "",
      userConfirmPass: "",
    };
  },
  created() {
    document.title = "Регистрация";
  },

  methods: {
    sendDataRegister() {
      const dataUser = {
        email: this.userEmail,
        pass: this.userPassword,
      };
      console.log(dataUser);
    },
  },
};
</script>